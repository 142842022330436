import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Heading, { HeadingVariant, HeadingScope } from 'src/components/Heading';
import Button, { ButtonAppearance } from 'src/components/Button';
import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div<Pick<ImageRowProps, 'isReversed'>>`
  display: flex;
  flex-direction: ${props => (props.isReversed ? 'column-reverse' : 'column')};
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 30px;
  }

  ${mediaQuery('lg')} {
    align-items: center;
    flex-direction: ${props => (props.isReversed ? 'row-reverse' : 'row')};

    &:not(:first-child) {
      margin-top: 60px;
    }
  }
`;

const ImageWrapper = styled.div`
  flex: 0 1 50%;
`;

const TextWrapper = styled.div<Pick<ImageRowProps, 'isReversed'>>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  margin-top: ${props => (props.isReversed ? '20px' : '0')};

  ${mediaQuery('lg')} {
    margin-left: ${props => (props.isReversed ? '80px' : '0')};
    margin-right: ${props => (props.isReversed ? '0' : '80px')};
    margin-top: 0;
  }
`;

const Text = styled.p`
  margin: 20px 0;
`;

interface ImageRowProps {
  text: string;
  image: any; //TODO
  imageAlt?: string;
  isReversed?: boolean;
  heading?: string;
  buttonText?: string;
  buttonUrl?: string;
}

const ImageRow: FC<ImageRowProps> = ({
  image,
  imageAlt,
  text,
  heading,
  isReversed,
  buttonText,
  buttonUrl,
}) => (
  <Wrapper isReversed={isReversed}>
    <TextWrapper isReversed={isReversed}>
      {heading && (
        <HeadingScope>
          <Heading variant={HeadingVariant.BRAVO}>{heading}</Heading>
        </HeadingScope>
      )}
      <Text dangerouslySetInnerHTML={{ __html: text }} />
      {buttonUrl && (
        <Button url={buttonUrl} appearance={ButtonAppearance.ALFA}>
          {buttonText}
        </Button>
      )}
    </TextWrapper>
    <ImageWrapper>
      {image && (
        <GatsbyImage
          image={getImage(image.localFile)}
          alt={imageAlt ? imageAlt : 'Piligrimo keliones'}
        />
      )}
    </ImageWrapper>
  </Wrapper>
);

export default ImageRow;
