import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import ImageRow from 'src/components/ImageRow';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';

interface AboutUsPageProps {
  data: any;
}

const AboutUsPage: FC<AboutUsPageProps> = ({ data }) => {
  const {
    wpPage: { aboutUsFields: acfFields, seo },
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        image={seo?.opengraphImage?.localFile}
      />

      <Banner
        image={
          acfFields.headerImage?.localFile.childImageSharp?.gatsbyImageData
        }
        title={acfFields.title}
        subtitle={acfFields.subtitle}
      />
      <Main>
        <ImageRow
          text={acfFields.firstColumn}
          image={acfFields.firstImage}
          imageAlt={acfFields.firstImage.altText}
          heading={acfFields.firstHeading}
        />
        <ImageRow
          text={acfFields.secondColumn}
          image={acfFields.secondImage}
          imageAlt={acfFields.secondImage.altText}
          heading={acfFields.secondHeading}
          isReversed
          buttonText="Kelionių galerija"
          buttonUrl="/galerija"
        />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query AboutUs {
    wpPage(slug: { eq: "apie-mus" }) {
      ...YoastSeoPage
      aboutUsFields {
        title
        subtitle
        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        firstColumn
        firstHeading
        firstImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED)
            }
          }
        }
        secondColumn
        secondHeading
        secondImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default AboutUsPage;
